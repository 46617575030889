import { Helmet } from 'react-helmet';
import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";

import { TabbedNav } from "components/TabbedNav";
import { StandardHero } from "components/Hero";
import ColumnCards from "components/ColumnCards";
import IconBanner from "components/IconBanner";
import { MegaAccordion, AccordionWrapper } from "components/Accordion";
import { TwoColumnAccordion } from "components/Accordion/TwoColumnAccordion";
import { ConnectWithExpert, TextIntroTwoColumn, IntroH1, H1TextIntroOneColumn, IntroThreeColumn, TextTwoColumnList, ResourceList } from "components/CopyBlocks/Intro";
import { ColorDivider } from "components/Dividers/Dividers";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { ImageBlockNew } from "components/ImageBlocks";
import { ImageCardColumn } from "components/ImageCard/ImageCardColumn";
import TriCards from "components/TriCards";
import { TabbedCarousel } from "components/TabbedCarousel/TabbedCarousel";
import { FeaturedCards } from "components/FeaturedCards/FeaturedCards";
import { FocusCarousel } from "components/FocusCarousel/index";
import { InfographicStandard } from "components/InfographicStandard/InfographicStandard";
import PhotoCollage from "components/Collages/PhotoCollage";
import ThreeColumnImageCarousel from "components/ThreeColumnImageCarousel/index";
import { OurTeamCards } from "components/OurTeamCards/index";
import { ImageCarousel5050 } from "components/Carousels";
import { H1ImageCarousel } from "components/H1ImageCarousel/H1ImageCarousel";
import { ColumnListContainer, FourRowIcon } from "components/CopyBlocks/ColumnList";
import { NewsCardCarousel } from "components/NewsCardCarousel/NewsCardCarousel";
import { SkeletonLoader } from "components/FilteredList/FilteredList";
import ListViewCalendar from "components/ListViews/ListViewsCalendar";

import './_index.scss';

const SpecialEventsTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Special Events']),
    slug: slug
  });

  const componentsData = pageData[0]?.components
  const seoData = pageData[0]?.attributes.seo;
  if (!componentsData) return <SkeletonLoader count={10} />

  return (
    <div>
      {seoData && <Helmet>
        <title>{seoData.meta_title || 'Community Foundation of Richmond'}</title>
        <meta name="description" content={seoData.meta_description || 'Community Foundation of Richmond'} />
      </Helmet>}
      <StrapiDynamicZone
        components={{
          TabbedNav: TabbedNav,
          ColumnCards: ColumnCards,
          Accordion: AccordionWrapper,
          MegaAccordion: MegaAccordion,
          TwoColumnAccordion: TwoColumnAccordion,
          IconBanner: IconBanner,
          DeprecatedStandardHero: StandardHero,
          ConnectWithExpert: ConnectWithExpert,
          ColorDivider: ColorDivider,
          ImageBlockNew: ImageBlockNew,
          TextIntroTwoColumn: TextIntroTwoColumn,
          Video: VideoPlayerWithWrapper,
          ImageCardColumn: ImageCardColumn,
          TriCards: TriCards,
          StandardHero: StandardHero,
          IntroH1: IntroH1,
          TabbedCarousel: TabbedCarousel,
          FeaturedCards: FeaturedCards,
          InfographicStandard: InfographicStandard,
          H1TextIntroOneColumn: H1TextIntroOneColumn,
          IntroThreeColumn: IntroThreeColumn,
          TextTwoColumnList: TextTwoColumnList,
          PhotoCollage: PhotoCollage,
          FocusCarousel: FocusCarousel,
          ThreeColumnImageCarousel: ThreeColumnImageCarousel,
          OurTeamCards: OurTeamCards,
          ImageCarousel5050: ImageCarousel5050,
          H1ImageCarousel: H1ImageCarousel,
          ColumnListContainer: ColumnListContainer,
          NewsCardCarousel: NewsCardCarousel,
          FourRowIcon: FourRowIcon,
          ListViewCalendar: ListViewCalendar,
          ResourceList: ResourceList,
        }}
        data={componentsData}
      />
    </div>
  )
}

export default SpecialEventsTemplate;
