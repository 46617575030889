import { Helmet } from 'react-helmet';
import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";
import { TextIntroTwoColumn, IntroH1, H1TextIntroOneColumn, IntroThreeColumn, ResourceList } from "components/CopyBlocks/Intro";
import { StandardHero } from "components/Hero";
import { ImageBlockNew } from "components/ImageBlocks";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { NewsCardCarousel } from "components/NewsCardCarousel/NewsCardCarousel";
import { InfographicStandard } from "components/InfographicStandard/InfographicStandard";
import ListView from "components/ListViews/ListView";
import { FilteredList } from "components/FilteredList/FilteredList";
import { FourRowIcon } from "components/CopyBlocks/ColumnList";
import ListViewCalendar from "components/ListViews/ListViewsCalendar";
import './_index.scss';

const BlogTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Blog']),
    slug: slug
  });

  const componentsData = pageData[0]?.components
  const seoData = pageData[0]?.attributes.seo;
  if (!componentsData) return null;

  return (
    <div>
      {seoData && <Helmet>
        <title>{seoData.meta_title || 'Community Foundation of Richmond'}</title>
        <meta name="description" content={seoData.meta_description || 'Community Foundation of Richmond'} />
      </Helmet>}
      <StrapiDynamicZone
        components={{
          FilteredList: FilteredList,
          H1TextIntroOneColumn: H1TextIntroOneColumn,
          StandardHero: StandardHero,
          ImageBlockNew: ImageBlockNew,
          Video: VideoPlayerWithWrapper,
          NewsCardCarousel: NewsCardCarousel,
          TextIntroTwoColumn: TextIntroTwoColumn,
          IntroThreeColumn: IntroThreeColumn,
          IntroH1: IntroH1,
          InfographicStandard: InfographicStandard,
          ListView: ListView,
          FourRowIcon: FourRowIcon,
          ListViewCalendar: ListViewCalendar,
          ResourceList: ResourceList,
        }}
        data={componentsData} // Pass only the components array
      />
    </div>
  )
}

export default BlogTemplate;
