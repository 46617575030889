import { Helmet } from 'react-helmet';
import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";

import { StandardHero } from "components/Hero";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { FilteredList, SkeletonLoader } from "components/FilteredList/FilteredList";
import { ResourceCard } from "components/ResourceCard/ResourceCard";
import { FourRowIcon } from "components/CopyBlocks/ColumnList";
import ListViewCalendar from "components/ListViews/ListViewsCalendar";

import './_index.scss';
import { ResourceList } from 'components/CopyBlocks/Intro';

const VideoTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Video Pages']),
    slug: slug
  });

  const componentsData = pageData[0]?.components;
  const seoData = pageData[0]?.attributes.seo;
  if (!componentsData) return <SkeletonLoader count={10} />

  return (
    <div>
      {seoData && <Helmet>
        <title>{seoData.meta_title || 'Community Foundation of Richmond'}</title>
        <meta name="description" content={seoData.meta_description || 'Community Foundation of Richmond'} />
      </Helmet>}
      <StrapiDynamicZone
        components={{
          Video: VideoPlayerWithWrapper,
          StandardHero: StandardHero,
          FilteredList: FilteredList,
          ResourceCard: ResourceCard,
          FourRowIcon: FourRowIcon,
          ListViewCalendar: ListViewCalendar,
          ResourceList: ResourceList,
        }}
        data={componentsData} // Pass only the components array
      />
    </div>
  )
}

export default VideoTemplate;
